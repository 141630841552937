import React from 'react';
import { useSelector } from 'react-redux';
import CategoryCard from './CategoryCard';
import { categoriesSelector, subCategoriesSelector } from '../../../modules/selectors/standort/categories';
import _ from 'lodash';
import Meisterkachel from '../Meisterkachel/Meisterkachel';

function CategoryCards({ showCurrentSubCategories, categories: categoriesOverride }) {
    const rootCategories = useSelector(categoriesSelector);
    const subCategories = useSelector(subCategoriesSelector);

    const categories = categoriesOverride ?? (showCurrentSubCategories ? subCategories : rootCategories);

    if (_.isEmpty(categories)) {
        return (
            <section className="uk-section uk-section-muted uk-section-small">
                <div className="uk-container uk-container-xsmall">
                    <Meisterkachel />
                </div>
            </section>
        );
    }

    return (
        <section className="uk-section uk-section-default uk-section-small">
            <div className="uk-container">
                <div className="uk-grid uk-grid-small uk-child-width-1-3@s uk-child-width-1-4@m" data-uk-grid="">
                    {_.sortBy(categories, ['title']).map(category => (
                        <CategoryCard key={category._id} category={category} />
                    ))}
                </div>
            </div>
        </section>
    );
}

export default CategoryCards;
