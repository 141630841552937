import { relatedCategoriesResponseSelector } from '../../selectors/standort/relatedCategories';
import { shouldFetch } from '../../fetchStatus';
import { FETCH_RELATED_CATEGORIES_FAILURE, FETCH_RELATED_CATEGORIES_REQUEST, FETCH_RELATED_CATEGORIES_SUCCESS } from '../../actionTypes';
import { apiClient } from '../../../helpers/apiHelperV2';
import { standortUrlSelector } from '../../selectors/url';

export const fetchRelatedCategoriesIfNeeded = () => {
    return async function (dispatch, getState) {
        const state = getState();

        const standortUrl = standortUrlSelector(state);
        const relatedCategoriesResponse = relatedCategoriesResponseSelector(state);

        if (shouldFetch(relatedCategoriesResponse.status)) {
            dispatch({
                type: FETCH_RELATED_CATEGORIES_REQUEST,
                payload: { standortUrl },
            });
            try {
                const res = await apiClient.get('/api/v1/MayInterest', {
                    params: {
                        standortUrl,
                    },
                });
                dispatch({
                    type: FETCH_RELATED_CATEGORIES_SUCCESS,
                    payload: { standortUrl, relatedCategories: res.data.data },
                });
            } catch (error) {
                dispatch({
                    type: FETCH_RELATED_CATEGORIES_FAILURE,
                    payload: { standortUrl, error },
                });
            }
        }
    };
};
