import React from 'react';
import { useSelector } from 'react-redux';
import { standortUrlSelector } from '../../../modules/selectors/url';
import { apiClient, useAsyncFunction } from '../../../helpers/apiHelperV2';
import CardSlider from '../layout/CardSlider';
import ProductCard from '../layout/ProductCard';
import ReduxSuspense from '../ReduxSuspense/ReduxSuspense';
import InlineSpinner from '../Spinner/InlineSpinner';
import _ from 'lodash';

function TopSeller() {
    const standortUrl = useSelector(standortUrlSelector);

    const topSellerResponse = useAsyncFunction(async () => apiClient.get(`/api/v1/standorte/${standortUrl}/bestseller`), [standortUrl]);

    return (
        <ReduxSuspense response={topSellerResponse} error={null} fallback={<InlineSpinner />}>
            {data => {
                if (_.isEmpty(data?.data)) return null;
                return (
                    <section className="uk-section uk-section-default uk-section-small uk-padding-remove-bottom">
                        <div className="uk-container uk-margin-medium-top">
                            <h2 className="uk-text-light">Top Seller</h2>
                            <h3>Besonders beliebt bei unseren Kunden</h3>
                            <CardSlider>
                                {data.data.map(product => (
                                    <li key={product.id}>
                                        <ProductCard product={product} />
                                    </li>
                                ))}
                            </CardSlider>
                        </div>
                    </section>
                );
            }}
        </ReduxSuspense>
    );
}

export default TopSeller;
