import React from 'react';
import { useSelector } from 'react-redux';
import Image from '../../components/Image/Image';
import BreadCrumbSelector from '../../components/BreadCrumb/BreadCrumbSelector';
import CategoryCards from '../../components/Categories/CategoryCards';
import { categoryUrlSelector } from '../../../modules/selectors/url';
import { hideHeroBannerSelector } from '../../../modules/selectors/standort/theme';
import Offers from '../../components/offers/Offers';

function CategoryOverview() {
    const categoryUrl = useSelector(categoryUrlSelector);
    const hideHeroBanner = useSelector(hideHeroBannerSelector);
  //  console.log(categoryUrl)

    return (
        <>
            {!hideHeroBanner && (
                <section className="uk-section-default">
                    <div className="uk-container uk-padding-remove">
                        <Image
                            src={`https://techno-images.s3.eu-central-1.amazonaws.com/cat-images/main/${categoryUrl}.jpg`}
                            alt="Kategoriebild"
                            resizeWidth={1200}
                        />
                    </div>
                </section>
            )}
            <BreadCrumbSelector />
            {categoryUrl === "angebote"? <Offers/> : <CategoryCards showCurrentSubCategories />}
        </>
    );
}

export default CategoryOverview;
