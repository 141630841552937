import React from 'react';
import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import _ from 'lodash';
import { relatedCategoriesSelector } from '../../../modules/selectors/standort/relatedCategories';
import { fetchRelatedCategoriesIfNeeded } from '../../../modules/actions/standort/relatedCategories';
import CategoryCard from '../Categories/CategoryCard';

function RelatedCategories({ relatedCategories }) {
    if (_.isEmpty(relatedCategories)) return null;
    return (
        <>
            <h3>Das könnte Sie interessieren</h3>
            <h5>Schneller finden</h5>
            <div className="uk-grid uk-grid-small uk-child-width-1-2@s" data-uk-grid="">
                {relatedCategories.map(category => (
                    <CategoryCard key={category._id} category={category} />
                ))}
            </div>
        </>
    );
}

const frontload = async props => {
    await Promise.all([props.fetchRelatedCategoriesIfNeeded()]);
};

const mapStateToProps = state => ({
    relatedCategories: relatedCategoriesSelector(state),
});

const mapDispatchToProps = {
    fetchRelatedCategoriesIfNeeded,
};

export default connect(mapStateToProps, mapDispatchToProps)(frontloadConnect(frontload)(RelatedCategories));
