import Meisterkachel from './Meisterkachel';
import RelatedCategories from './RelatedCategories';
import React from 'react';

function MeisterkachelAndRelatedCategories() {
    return (
        <section className="uk-section uk-section-default uk-section-small">
            <div className="uk-container">
                <div className="uk-grid uk-grid-small uk-grid-match uk-child-width-1-2@m" data-uk-grid="">
                    <div>
                        <Meisterkachel />
                    </div>
                    <div>
                        <RelatedCategories />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default MeisterkachelAndRelatedCategories;
