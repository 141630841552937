import React, { useEffect } from 'react';
import Status from '../../../components/Status/Status';

import {
    markenStandortFailureSelector,
    markenStandortUrlWithFallbackSelector,
} from '../../../../modules/selectors/standort/markenStandort';
import { useDispatch, useSelector } from 'react-redux';
import ContactModal from '../../../components/Anfrage/ContactModal.js';
import TopSeller from '../../../components/TopSeller/TopSeller';
import Image from '../../../components/Image/Image';
import { notFoundDidMount, notFoundDidUnmount } from '../../../../modules/actions/notFound';
import Button from '../../../components/form-elements/Button';
import { InternalLink } from '../../../components/form-elements/Link';
import MeisterkachelAndRelatedCategories from '../../../components/Meisterkachel/MeisterkachelAndRelatedCategories';

const NotFound = () => {
    const markenStandortFailure = useSelector(markenStandortFailureSelector);
    const markenStandortUrlWithFallback = useSelector(markenStandortUrlWithFallbackSelector);
    const dispatch = useDispatch();

    // Track mount state in redux
    useEffect(() => {
        dispatch(notFoundDidMount());

        return () => {
            dispatch(notFoundDidUnmount());
        };
    }, [dispatch]);

    return (
        <Status code={404}>
            <section className="uk-section uk-section-muted uk-section-small uk-padding-remove-bottom">
                <div className="uk-container uk-container-small">
                    <div className="uk-grid uk-grid-small uk-flex uk-flex-middle uk-flex-between" data-uk-grid="">
                        <div>
                            <h1 className="uk-heading-medium uk-text-primary">Schade...</h1>
                            <p>
                                ...da ist etwas schief gelaufen. <br />
                                Diese Seite haben wir nicht gefunden.
                            </p>
                        </div>
                        <div>
                            <Image alt="404" src="https://techno-images.s3.eu-central-1.amazonaws.com/images/404/404.png" />
                        </div>
                    </div>
                    <h2 className="uk-h1 uk-margin-remove-top">Wie können wir Ihnen weiterhelfen?</h2>
                    <div className="uk-grid uk-grid-small uk-child-width-1-2@s uk-margin-large-bottom" data-uk-grid="">
                        {!markenStandortFailure && (
                            <div>
                                <ContactModal>
                                    {({ openModal }) => (
                                        <Button fullWidth modifier="primary" onClick={openModal}>
                                            KONTAKT AUFNEHMEN
                                        </Button>
                                    )}
                                </ContactModal>
                            </div>
                        )}
                        <div>
                            <InternalLink modifier="secondary" to="/" newStandortUrl={markenStandortUrlWithFallback} fullWidth>
                                ZUR STARTSEITE
                            </InternalLink>
                        </div>
                    </div>
                </div>
            </section>
            {!markenStandortFailure && (
                <>
                    <TopSeller />
                    <MeisterkachelAndRelatedCategories />
                </>
            )}
        </Status>
    );
};

export default NotFound;
