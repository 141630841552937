import { NOT_FOUND_DID_MOUNT, NOT_FOUND_DID_UNMOUNT } from '../actionTypes';

export const notFoundDidMount = () => {
    return {
        type: NOT_FOUND_DID_MOUNT,
    };
};

export const notFoundDidUnmount = () => {
    return {
        type: NOT_FOUND_DID_UNMOUNT,
    };
};
