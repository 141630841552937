
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { apiClient, useAsyncFunction } from '../../../helpers/apiHelperV2';
import ProductCard from '../layout/ProductCard';
import Button from '../form-elements/Button';
import InlineSpinner from '../Spinner/InlineSpinner';
import ReduxSuspense from '../ReduxSuspense/ReduxSuspense';
import { standortUrlSelector } from '../../../modules/selectors/url';
import { convertLegacyAutoTyp } from '../../../helpers/tiresHelper';


function Offers() {
    const [index, setIndex] = useState(20);
    const [totalProducts, setTotalProducts] = useState(0);
    const [isCompleted, setIsCompleted] = useState(false);

    const standortUrl = useSelector(standortUrlSelector);

    const angeboteProductResponse = useAsyncFunction(async () => {
        const {
            data
        } = await apiClient.get(`/api/v1/standorte/${standortUrl}/angebote`)
        setTotalProducts(data.length);
        if (index >= data.length) { setIsCompleted(true) }
        return { data };
    }, [index, standortUrl]);

    const loadMore = () => {
        setIndex(index + 20);
        if (index >= totalProducts) {
            setIsCompleted(true);
        } else {
            setIsCompleted(false);
        }
    }

    // console.log(standortUrl)
    // console.log(angeboteProductResponse.data)
    // console.log(angeboteProductResponse.error)
    return (
        <ReduxSuspense response={angeboteProductResponse} error={null} fallback={<InlineSpinner />}>
            {({ data }) => {
                const initialPosts = data.slice(0, index);
                if (!data) return null;
                return (
                    <section className="uk-section uk-section-default uk-padding-remove-bottom">
                        <div className="uk-container">
                            <h3>{data.length}{data.length === 1 ? " Ergebnis" : " Ergebnisse"}</h3>
                            <div>
                                <ul className="uk-grid uk-grid-match uk-child-width-1-2 uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-5@l uk-margin-remove-left">
                                    {initialPosts.map(product => (
                                        <li className="uk-padding-small vehicle_products" key={product.id}>
                                            <ProductCard product={product} forOffer={true} />
                                        </li>
                                    ))}
                                </ul>
                                {/* </div> */}
                            </div>
                            <div className="uk-container uk-text-center uk-margin-top">
                                {!isCompleted ? (
                                    <Button onClick={loadMore} type="button" modifier="primary">
                                        Mehr laden
                                    </Button>
                                ) : null}
                            </div>
                        </div>
                    </section>
                )
            }}
        </ReduxSuspense >
    )
}

export default Offers;