import { createSelector } from 'reselect';
import { standortStateSelector } from '../standort';
import { pendingResponse } from '../../fetchStatus';

export const relatedCategoriesResponseSelector = createSelector(
    [standortStateSelector],
    standortState => standortState.relatedCategories || pendingResponse
);

export const relatedCategoriesSelector = createSelector(
    [relatedCategoriesResponseSelector],
    relatedCategoriesResponse => relatedCategoriesResponse?.data
);
